<template>
    <modal ref="modalReportarReclamo" titulo="Reportar un reclamo" adicional="Reportar" @adicional="postReclamo">
        <ValidationObserver ref="form">
            <div class="row mx-0 j-center">
                <div class="col-8 my-3">
                    <p class="text-general pl-3 f-12">
                        Motivo del reclamo
                    </p>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="Motivo del reclamo">
                        <el-select v-model="model.idMotivo" placeholder="Seleccionar" class="w-100 br-12">
                            <el-option
                            v-for="(mot,index) in motivos"
                            :key="index"
                            :value="mot.id"
                            :label="mot.nombre"
                            />
                        </el-select>
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <p class="text-general pl-3 f-12 mt-4">
                        Cuéntanos la situación
                    </p>
                    <ValidationProvider v-slot="{ errors }" rules="max:250|required" name="Justificación">
                        <el-input v-model="model.justificacion" type="textarea" :rows="7" placeholder="¿Qué problema has tenido?" class="w-100 br-12" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-8 mt-3">
                    <p class="text-general pl-3 f-12 mb-1">
                        Adjuntar algunas evidencias
                    </p>
                    <div class="row mx-0 d-flex justify-content-around">
                        <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="height:120px;width:120px;background:#F8F9FF;border-radius:4px;" />
                        <slim-cropper ref="fotoReclamo2" :options="slimOptions" class="border cr-pointer" style="height:120px;width:120px;background:#F8F9FF;border-radius:4px;" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '@/services/pedidos/pedidos'

export default {
    data(){
        return {
            motivos: [],
            model: {
                idPedido: null,
                justificacion: null,
                idMotivo: null,
                estado: null,
            },
            

            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        toggle(idPedido, estado){
            this.model.idPedido = idPedido;
            this.model.estado = estado;
            this.limpiar();
            this.getMotivos();
            this.$refs.modalReportarReclamo.toggle();
        },

        async getMotivos(){
            try {
                let tipo = 5;
                const { data } = await Service.getMotivos(tipo)
                this.motivos = data.motivos
            } catch (error){
                this.errorCatch(error)
            }
        },

        async postReclamo(){
            try {
                let validate = await this.$refs.form.validate()
                if(!validate) return;

                this.model.evidencias = [
                    this.$refs.fotoReclamo1.instanciaCrop.dataBase64.output.image,
                    this.$refs.fotoReclamo2.instanciaCrop.dataBase64.output.image
                ].filter(el=>el);

                const {data} = await Service.postReclamo(this.model)
                this.notificacion('Mensaje', 'Se ha iniciado el reclamo', 'success');
                this.$emit('update');
                this.$refs.modalReportarReclamo.toggle();
            } catch (error){
                this.errorCatch(error)
            }
        },

        limpiar(){
            this.model.idMotivo = null
            this.model.justificacion = null;
            this.$refs.fotoReclamo1.instanciaCrop.remove()
            this.$refs.fotoReclamo2.instanciaCrop.remove()
        },
    }
}
</script>
